export const SET_ZOOM = 'SET_ZOOM';
export const SET_CENTER = 'SET_CENTER';
export const SET_ACTIVE_FLOOR = 'SET_ACTIVE_FLOOR';
export const SET_FLOOR_INFO = 'SET_FLOOR_INFO';
export const SET_CURRENT_STOPS = 'SET_CURRENT_STOPS';
export const SET_CURRENT_STOPS_GEOJSON = 'SET_CURRENT_STOPS_GEOJSON';
export const SET_CURRENT_MOT = 'SET_CURRENT_MOT';
export const SET_CLICK_LOCATION = 'SET_CLICK_LOCATION';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const SET_IS_FIELD_FOCUSED = 'SET_IS_FIELD_FOCUSED';
export const SET_SHOW_LOADING_BAR = 'SET_SHOW_LOADING_BAR';
export const SET_SELECTED_ROUTES = 'SET_SELECTED_ROUTES';
export const SET_IS_ROUTE_INFO_OPEN = 'SET_IS_ROUTE_INFO_OPEN';
export const SET_DIALOG_POSITION = 'SET_DIALOG_POSITION';
export const SET_DIALOG_SIZE = 'SET_DIALOG_SIZE';
export const SET_SEARCH_MODE = 'SET_SEARCH_MODE';
export const SET_RESOLVE_HOPS = 'SET_RESOLVE_HOPS';
export const SET_TRACKS = 'SET_TRACKS';
export const SET_MAX_EXTENT = 'SET_MAX_EXTENT';
export const SET_GENERALIZATION_ENABLED = 'SET_GENERALIZATION_ENABLED';
export const SET_GENERALIZATION_GRAPH = 'SET_GENERALIZATION_GRAPH';
export const SET_GENERALIZATION_ACTIVE = 'SET_GENERALIZATION_ACTIVE';
export const SET_MODE = 'SET_MODE';
export const SET_DEBUG_DIALOG_OPEN = 'SET_DEBUG_DIALOG_OPEN';
export const SET_STYLE = 'SET_STYLE';
